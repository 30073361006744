import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthCallback, SilentAuthCallback } from "./modules/auth/components/AuthCallback";
import { PrivateRoute } from "./modules/auth/components/PrivateRoute";
import Accounts from "./modules/account/screens/Accounts";
import Page404 from "./modules/_core/screens/Page404";
import ScrollToTop from "./modules/_core/providers/ScrollToTop";
import Alerts from "./modules/_core/providers/Alerts";
import UILoader from "./modules/_core/components/ui/UILoader";
import Logout from "./modules/auth/screens/Logout";
import NoUser from "./modules/_core/screens/NoUser";
import { SentryRoutes } from "./modules/_core/config/sentry";
import Providers from "./modules/_core/providers";
import { setupAuth } from "./modules/auth/utils";
import Login from "./modules/auth/screens/Login";
import AddUser from "./modules/users/screens/AddUser";
import Users from "./modules/users/screens/Users";

const App = () => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        await setupAuth();
        setIsReady(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  if (!isReady) return <UILoader overlay />;
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  );
};

function AppRoutes() {
  return (
    <Router>
      <Alerts />
      <ScrollToTop />
      <SentryRoutes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/no-user" element={<NoUser />} />
        <Route path="/oauth_callback" element={<AuthCallback />} />
        <Route path="/silent_oauth_callback" element={<SilentAuthCallback />} />

        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/new"
          element={
            <PrivateRoute>
              <AddUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Accounts />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </SentryRoutes>
    </Router>
  );
}

export default App;
