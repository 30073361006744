import { Amplify } from "aws-amplify";
import { AuthConfig } from "../../auth/api/types";

const extractUserPoolId = (url: string) => {
  const parts = url.split("/");

  return parts[parts.length - 1];
};

export const initAmplify = (config: AuthConfig) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: extractUserPoolId(config.tokenIssuerUrl),
        userPoolClientId: config.partnerPortalClientId,
      },
    },
  });
};
