import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../components/layout/Main";
import UIText from "../components/ui/UIText";

export const Page404 = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Main>
      <div className={classes.ctr}>
        <UIText variant="h3" gutterBottom>
          Page Not Found
        </UIText>
        <Button variant="contained" color="secondary" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "calc(100vh - 200px)",
    },
  })
);

export default Page404;
