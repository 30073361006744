import { IdentityUser } from "../../auth/api/types";

export function parseFirstName(user?: IdentityUser | null): string {
  return user?.profile.given_name ?? "";
}

export function parseLastName(user?: IdentityUser | null): string {
  return user?.profile.family_name ?? "";
}

export function parseFullName(user?: IdentityUser | null): string {
  let name = "";
  if (!user) return name;
  name = user.profile.given_name ?? "";
  if (user.profile.family_name) {
    name += " " + user.profile.family_name;
  }
  return name;
}

export function parseInitials(user?: IdentityUser | null): string {
  let initials = "";
  if (!user) return initials;
  (user.profile.given_name ?? "").charAt(0);
  if (user.profile.family_name) {
    initials += user.profile.family_name.charAt(0);
  }
  return initials;
}
