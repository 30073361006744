import { initAmplify } from "../../_core/lib/amplify";
import { getAuthConfig } from "../api";
import { setupIdentity } from "../api/identityAPI";
import { isCognitoUser } from "../api/utils";

export async function setupAuth() {
  try {
    const authConfig = await getAuthConfig();
    window.authConfig = authConfig;
    if (isCognitoUser()) {
      initAmplify(authConfig);
    } else {
      setupIdentity(authConfig.partnerPortalClientId, authConfig.tokenIssuerUrl);
    }
    return;
  } catch (error) {
    throw error;
  }
}
