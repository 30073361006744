import { getUser } from "./identityAPI";
import api from "../../_core/api";
import { AuthConfig, SignedInUser } from "./types";
import { fetchAuthSession } from "aws-amplify/auth";
import { isCognitoUser } from "./utils";

export const getIdentityUser: () => Promise<SignedInUser | undefined> = async () => {
  return isCognitoUser() ? getAmplifyUser() : getSSOUser();
};

export const getAmplifyUser: () => Promise<SignedInUser> = async () => {
  return fetchAuthSession().then((session) => {
    const idToken = session.tokens?.idToken;
    const accessToken = session.tokens?.accessToken;
    const expiresAt = session.tokens?.idToken?.payload?.exp ? session.tokens.idToken.payload.exp : undefined;
    const profile = session?.tokens?.idToken?.payload;

    const signedInUser: SignedInUser = {
      id_token: idToken?.toString(),
      access_token: accessToken?.toString(),
      token_type: "Bearer",
      expires_at: expiresAt,
      expired: expiresAt ? Date.now() >= expiresAt * 1000 : undefined,
      profile: {
        sub: session.userSub,
        ...profile,
      },
      session_state: null,
      scope: idToken?.payload?.scope,
    };

    return signedInUser;
  });
};

const getSSOUser = async (): Promise<SignedInUser | undefined> => {
  const user = await getUser();

  if (!user) {
    return undefined;
  }

  return {
    ...user,
    profile: {
      ...user?.profile,
    },
  };
};

export const doesHubUserExist = async () => {
  try {
    const response = await api({
      method: "GET",
      url: "/users/my/exists",
    });

    return response.status === 204;
  } catch (error) {
    return false;
  }
};

export const getAuthConfig = async () => {
  const { data }: { data: AuthConfig } = await api({
    method: "GET",
    url: "/auth-config/partner-portal",
    is_hub: true,
    skip_bearer_injection: true,
    skip_token_refresh: true,
  });

  if (!data?.tokenIssuerUrl || !data?.partnerPortalClientId) {
    throw Error(`Missing some of the mandatory attributes in the returned data: ${data}`);
  }

  return data;
};
