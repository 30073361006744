import { ReactElement, useEffect } from "react";
import { login } from "../api/identityAPI";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, Navigate } from "react-router";
import LoadingOverlay from "../../_core/components/LoadingOverlay";
import { isCognitoUser } from "../api/utils";
import { useIdentityUser, useHubUserExist } from "../hooks";

interface PrivateRouteProps {
  children: ReactElement<any, any> | null;
}

export const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context: any) => [context.authStatus]);

  const { data: identityUser, isFetching: identityUserFetching } = useIdentityUser();
  const hasValidIdentityUser = identityUser && !identityUser?.expired;

  const { data: doesHubUserExist, isFetching: hubUserExistenceFetching } = useHubUserExist({
    enabled: hasValidIdentityUser,
  });

  // Login
  useEffect(() => {
    if (!identityUserFetching && !hasValidIdentityUser) {
      if (isCognitoUser()) {
        navigate("/login");
      } else {
        login();
      }
    }
  }, [hasValidIdentityUser, identityUserFetching, navigate]);

  // Redirect to the `no-user` page if we don't have a user in Hub
  useEffect(() => {
    if (hasValidIdentityUser && !hubUserExistenceFetching && !doesHubUserExist) {
      setTimeout(() => navigate("/no-user", { replace: true }));
    }
  }, [hasValidIdentityUser, hubUserExistenceFetching, doesHubUserExist, navigate]);

  if (isCognitoUser() && authStatus !== "authenticated" && hasValidIdentityUser) {
    return <Navigate to="/login" />;
  }

  if (doesHubUserExist) {
    return children || <div />;
  }

  return <LoadingOverlay />;
};
