import { UserLite } from "../../users/api/types";
import { COGNITO_TOKEN_ISSUER_URL, impersonateCognitoUser, impersonateNonCognitoUser } from "./identityAPI";
import { IdentityUser } from "./types";

export const isCognitoUser = (issuer?: string) => {
  if (issuer) {
    return issuer.startsWith(COGNITO_TOKEN_ISSUER_URL);
  } else {
    return window.authConfig?.tokenIssuerUrl.startsWith(COGNITO_TOKEN_ISSUER_URL);
  }
};

export const impersonateHubUser = async (targetUser: UserLite, identityUser: IdentityUser | null) => {
  return isCognitoUser() ? impersonateCognitoUser(targetUser, identityUser) : impersonateNonCognitoUser(targetUser);
};
