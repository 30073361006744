import { PropsWithChildren } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import ThemeProvider from "./ThemeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Store from "../store/Store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      retry: 1,
    },
  },
});

function Providers({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator.Provider>
        <Store>
          <ThemeProvider>{children}</ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </Store>
      </Authenticator.Provider>
    </QueryClientProvider>
  );
}

export default Providers;
