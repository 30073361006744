import { SsoLogout } from "../components/SsoLogout";
import { isCognitoUser } from "../api/utils";
import { CognitoLogout } from "../components/CognitoLogout";

function Logout() {
  localStorage.clear();
  if (isCognitoUser()) {
    return <CognitoLogout />;
  } else {
    return <SsoLogout />;
  }
}

export default Logout;
