import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography, TypographyProps } from "@mui/material";

function UIText(props: TypographyProps) {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} {...props}>
      {typeof props.children === "string" ? props.children : <div>{props.children}</div>}
    </Typography>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {},
  })
);

export default UIText;
