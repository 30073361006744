import { UserManager, UserManagerSettings } from "oidc-client-ts";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { IdentityUser } from "./types";
import { getHubUserJwt } from "../../users/api";
import { UserLite } from "../../users/api/types";
import { isCognitoUser } from "./utils";

let mgr: UserManager;

export const COGNITO_TOKEN_ISSUER_URL = "https://cognito-idp.eu-west-2.amazonaws.com";

export const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
  region: "eu-west-2",
});

export function setupIdentity(clientId: UserManagerSettings["client_id"], tokenIssuerUrl: UserManagerSettings["authority"]) {
  // Without Advanced Security turned on (costs around $4k/month), Cognito can neither adjust Access Token scopes nor claims.
  // Hence, using ID token instead, to avoid excessive costs.
  // See https://auth0.com/blog/id-token-access-token-what-is-the-difference
  // See https://aws.amazon.com/blogs/security/how-to-customize-access-tokens-in-amazon-cognito-user-pools
  // Since Cognito pre-token-generation Lambda does not provide requested token scopes by clients,
  // The pre-token-generation Lambda cannot copy the scopes to the ID token.
  // Instead, Cognito Groups replace scopes, because groups are available in the pre-token-generation Lambda request.
  // The groups are copied to the ID token's scope so that the Hub backend can authorise users.
  // That is why there is no `HUB_FE_API` scope provided for Cognito.
  // TODO Remove this once Affinity SSO tenants are migrated to Cognito
  const partnerPortalApiScopes = tokenIssuerUrl?.startsWith(COGNITO_TOKEN_ISSUER_URL)
    ? "aws.cognito.signin.user.admin"
    : "IdentityServerApi HUB_PORTAL_API"; // The `IdentityServerApi` scope is necessary for Affinity SSO impersonation

  const config: UserManagerSettings = {
    client_id: clientId,
    authority: tokenIssuerUrl,
    redirect_uri: window.location.origin + "/oauth_callback",
    silent_redirect_uri: window.location.origin + "/silent_oauth_callback",
    post_logout_redirect_uri: window.location.origin,
    automaticSilentRenew: true,
    validateSubOnSilentRenew: true,
    response_type: "code",
    loadUserInfo: true,
    scope: `openid profile email ${partnerPortalApiScopes}`,
  };

  mgr = new UserManager(config);
  return mgr;
}

export function logout() {
  return mgr?.signoutRedirect({
    extraQueryParams: {
      client_id: window.authConfig?.partnerPortalClientId,
      logout_uri: window.location.origin,
    },
  });
}

export function login() {
  return mgr?.signinRedirect();
}

export async function loginCallback() {
  try {
    const token = await mgr.signinRedirectCallback();
    return token;
  } catch (error) {
    console.log("Login callback error:", error);
  }
}

export function silentLoginCallback() {
  return mgr
    .signinCallback()
    .then(function () {})
    .catch(function (e: Error) {
      console.error(e);
    });
}

export function getUser(): Promise<IdentityUser | null> {
  return mgr?.getUser();
}

export function removeUser() {
  return mgr?.removeUser();
}

export function renewTokens() {
  return mgr?.startSilentRenew();
}

export const impersonateNonCognitoUser = async (user: UserLite) => {
  const data = await getHubUserJwt(user);
  if (data.access_token && data.hub_url && data.sso_url) {
    const url = `${data.hub_url}/jwt-login?jwt=${data.access_token}`;
    window.open(url, "_blank");
  }
};

export const impersonateCognitoUser = async (targetUser: UserLite, identityUser: IdentityUser | null) => {
  const targetUserId = targetUser.id ?? "";
  const clientId = window.authConfig?.partnerPortalClientId;
  const jwtToken = identityUser?.access_token ?? "";
  try {
    const initiateAuthCommand = new InitiateAuthCommand({
      AuthFlow: "CUSTOM_AUTH",
      ClientId: clientId,
      AuthParameters: { USERNAME: targetUserId },
    });

    const { Session } = await cognitoIdentityProviderClient.send(initiateAuthCommand);
    if (!Session) {
      throw new Error("Failed to initiate impersonation session.");
    }

    const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand({
      ClientId: clientId,
      ChallengeName: "CUSTOM_CHALLENGE",
      ChallengeResponses: { USERNAME: targetUserId, ANSWER: jwtToken },
      ClientMetadata: {
        type: "IMPERSONATION",
        impersonatorEmail: identityUser?.profile.email ?? "",
        impersonatorUserId: (identityUser?.profile?.["cognito:username"] as string) ?? "",
        impersonatorGivenName: identityUser?.profile.given_name ?? "",
        impersonatorFamilyName: identityUser?.profile.family_name ?? "",
      },
      Session: Session,
    });

    const { AuthenticationResult } = await cognitoIdentityProviderClient.send(respondToAuthChallengeCommand);

    if (!AuthenticationResult || !AuthenticationResult.IdToken) {
      throw new Error("Failed to obtain authentication result.");
    }

    const url = `${window.authConfig.customerPortalUrl}/jwt-login?client_id=${clientId}&response_type=code&jwt=${AuthenticationResult.IdToken}&access_token=${AuthenticationResult.AccessToken}&redirect_uri=${window.authConfig.customerPortalUrl}`;
    window.open(url, "_blank");
  } catch (error: any) {
    console.error("Error during impersonation flow:", error.message || error);
    throw new Error("Impersonation failed.");
  }
};
