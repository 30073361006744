import { useQuery } from "@tanstack/react-query";
import { AjaxResponse } from "../../_core/api/types";
import { getBrand } from "../api";
import { Brand } from "../api/types";
import { DEFAULT_BRAND } from "../utils";

export function useGetBrand(options?: any) {
  return useQuery<Brand, AjaxResponse>({
    queryKey: ["brand"],
    queryFn: () => getBrand(),
    ...options,
  });
}

export function useBrand() {
  const { data: brand } = useGetBrand();
  return brand || DEFAULT_BRAND;
}

export function useBrandColors() {
  const brand = useBrand();
  return {
    primary: brand?.primaryColour,
    secondary: brand?.secondaryColour,
    tertiary: brand?.tertiaryColour,
    primary_text: brand?.primaryTextColour,
    secondary_text: brand?.secondaryTextColour,
    tertiary_text: brand?.tertiaryTextColour,
  };
}

export function useBrandName() {
  const brand = useBrand();
  return brand?.name;
}

export function useLogo() {
  const brand = useBrand();
  return brand?.logoUrl;
}

export function useBrandWelcomeText() {
  const brand = useBrand();
  return brand?.welcomeText || "Welcome to " + brand?.name;
}

export function useLoginBackground() {
  const brand = useBrand();
  return brand?.loginBg;
}

export function usePoweredByLogo() {
  const brand = useBrand();
  return brand?.poweredByImg;
}
