import { type PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Brand } from "../../brand/api/types";
import { getBrand } from "../../brand/api";
import actions from "../store/Actions";
import { useStore } from "../hooks";
import LoadingOverlay from "../components/LoadingOverlay";
import { useIdentityUser } from "../../auth/hooks";
import AmplifyThemeProvider from "./AmplifyThemeProvider";
import MUIThemeProvider from "./MUIThemeProvider";

export default function ThemeProvider({ children }: PropsWithChildren) {
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useStore();
  const { data: identityUser } = useIdentityUser();

  const setFavIcon = (icon?: string) => {
    const favicon: HTMLLinkElement | any = document.getElementById("favicon");
    if (favicon) favicon.href = icon || "/favicon.ico";
  };

  const setBrand = useCallback(
    (brand?: Brand) => {
      if (brand) {
        dispatch({ type: actions.SET_BRAND, payload: brand });
      }
      setFavIcon(brand?.faviconUrl || brand?.logoUrl);
      setIsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    setIsLoading(true);
    setBrand();
  }, [setBrand]);

  useEffect(() => {
    let isUnmounted = false;
    setIsLoading(true);
    getBrand()
      .then((resp) => {
        if (isUnmounted) return;
        setBrand(resp);
      })
      .catch(() => {
        if (isUnmounted) return;
        setBrand();
      });
    return () => {
      isUnmounted = true;
    };
  }, [setBrand, identityUser]);

  if (isLoading) return <LoadingOverlay />;
  return (
    <MUIThemeProvider>
      <AmplifyThemeProvider>{children}</AmplifyThemeProvider>
    </MUIThemeProvider>
  );
}
