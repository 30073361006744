import React from "react";
import { ThemeProvider as MUIProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { SimplePaletteColorOptions, Theme } from "@mui/material/styles";
import { createTheme, lighten, darken } from "@mui/material/styles";
import { useBrandColors } from "../../brand/hooks";
import muiThemeConfig from "./muiThemeConfig";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
  }
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export default function MUIThemeProvider({ children }: React.PropsWithChildren) {
  const brandedTheme = useBrandedMuiTheme();
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <MUIProvider theme={brandedTheme}>{children}</MUIProvider>
    </StyledEngineProvider>
  );
}

export function useBrandedMuiTheme() {
  const colors = useBrandColors();

  const brandedPalette = {
    ...muiThemeConfig?.palette,
    primary: {
      main: colors?.primary,
      light: lighten(colors?.primary, 0.5),
      dark: darken(colors?.primary, 0.2),
      contrastText: colors?.primary_text,
    },
    secondary: {
      main: colors?.secondary,
      light: lighten(colors?.secondary, 0.5),
      dark: darken(colors?.secondary, 0.2),
      contrastText: colors?.secondary_text,
    },
    tertiary: {
      main: colors?.tertiary,
      light: lighten(colors?.tertiary, 0.5),
      dark: darken(colors?.tertiary, 0.35),
      contrastText: colors?.tertiary_text,
    },
  };

  const components = {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover": {
            color: brandedPalette?.primary?.contrastText,
            backgroundColor: brandedPalette?.primary?.main,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          "& button.Mui-disabled": {
            opacity: 0.5,
          },
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& .datatables-noprint": {
            margin: "100px 0",
            marginLeft: "40%",
            maxWidth: "fit-content",
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          "& [aria-label='View Columns']": {
            "&:after": {
              content: '"Columns"',
              fontSize: 14,
              position: "relative",
              left: "5px",
              color: brandedPalette?.primary?.main,
              paddingRight: "5px",
            },
          },
          "& button": {
            color: brandedPalette?.primary?.main,
            padding: "5px 10px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: brandedPalette?.primary?.light,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: any }) => ({
          ...(ownerState.severity === "success" &&
            ownerState.variant === "standard" && {
              backgroundColor: (brandedPalette?.success as SimplePaletteColorOptions)?.light,
              color: (brandedPalette?.success as SimplePaletteColorOptions)?.dark,
              ".MuiAlert-icon": {
                color: (brandedPalette.success as SimplePaletteColorOptions)?.main,
              },
            }),
        }),
      },
    },
  };

  return createTheme({
    ...muiThemeConfig,
    palette: brandedPalette,
    components: {
      ...muiThemeConfig.components,
      ...components,
    },
  });
}
