import { type PropsWithChildren } from "react";
import { Theme, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { lighten, Palette, useTheme } from "@mui/material";

const generateColorTokens = (type: "primary" | "secondary" | "tertiary", palette: Palette) => {
  const color = palette[type];
  return {
    [type]: {
      "100": { value: color.dark },
      "90": { value: color.dark },
      "80": { value: color.main },
      "70": { value: color.main },
      "60": { value: color.light },
      "50": { value: lighten(color.main, 0.5) },
      "40": { value: lighten(color.main, 0.6) },
      "30": { value: lighten(color.main, 0.7) },
      "20": { value: lighten(color.main, 0.8) },
      "10": { value: lighten(color.main, 0.9) },
    },
  };
};

export default function AmplifyThemeProvider({ children }: PropsWithChildren) {
  const muiTheme = useTheme();
  const brandedTheme: Theme = {
    name: "branded-theme",
    tokens: {
      colors: {
        ...generateColorTokens("primary", muiTheme.palette),
        ...generateColorTokens("secondary", muiTheme.palette),
        ...generateColorTokens("tertiary", muiTheme.palette),
      },
      fonts: {
        default: {
          static: { value: muiTheme.typography.fontFamily || "" },
          variable: { value: muiTheme.typography.fontFamily || "" },
        },
      },
    },
  };
  return <ThemeProvider theme={brandedTheme}>{children}</ThemeProvider>;
}
