import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Avatar } from "@mui/material";
import { parseFullName, parseInitials } from "../../../users/utils";
import UIText from "./UIText";
import { useIdentityUser } from "../../../auth/hooks";

function UIAvatar({ textOnly = false }) {
  const classes = useStyles();
  const { data: identityUser } = useIdentityUser({ queryKey: ["avatarUser"] });

  if (textOnly) {
    return (
      <UIText variant="subtitle2" className={classes.name}>
        {parseFullName(identityUser)}
      </UIText>
    );
  }
  return <Avatar className={classes.root}>{parseInitials(identityUser)}</Avatar>;
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {},
    name: { fontWeight: "bold" },
  })
);

export default UIAvatar;
