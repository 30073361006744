import { useNavigate } from "react-router-dom";
import { Drawer, Hidden, List, ListItem, ListItemText } from "@mui/material";
import { useTheme, Theme, lighten, darken } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import AccountsIcon from "@mui/icons-material/ContactsSharp";
import { useLogo } from "../../../brand/hooks";
import { parseFirstName } from "../../../users/utils";
import UIText from "../ui/UIText";
import { useIdentityUser } from "../../../auth/hooks";

interface MainProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  hasWelcome?: boolean;
}
export const drawerWidth = 264;

function SideMenu({ mobileOpen, handleDrawerToggle, hasWelcome = false }: MainProps) {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: identityUser } = useIdentityUser();
  const logo = useLogo();

  const showLink = (type: string) => {
    switch (type) {
      case "accounts":
        return true;
      default:
        return false;
    }
  };

  const DrawerList = () => {
    return (
      <div role="presentation" className={classes.listWrap}>
        <div className={classes.logo}>
          <div
            onClick={() => navigate("/")}
            data-testid="nav-drawer-logo"
            style={{
              width: "200px",
              height: "110px",
              background: `url('${logo}') center / contain no-repeat`,
              cursor: "pointer",
            }}
          />
        </div>
        {hasWelcome && (
          <UIText className={classes.welcome} variant="subtitle2">
            Welcome
            {parseFirstName(identityUser) ? ", " + parseFirstName(identityUser) : ""}
          </UIText>
        )}
        <List disablePadding={false} className={classes.links}>
          {showLink("accounts") && (
            <ListItem className={classes.listItem} data-testid="nav-drawer-item-home" onClick={() => navigate("/")}>
              <AccountsIcon className={classes.icon} />
              <ListItemText primary="Accounts" classes={{ primary: classes.itemText }} />
            </ListItem>
          )}
        </List>
      </div>
    );
  };

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerList />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerList />
        </Drawer>
      </Hidden>
    </nav>
  );
}

const useStyles = makeStyles<Theme, { drawerWidth: number }>((theme: Theme) =>
  createStyles({
    listWrap: {
      width: drawerWidth,
      backgroundColor: theme.palette.tertiary.main,
      height: "100%",
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px 24px",
    },
    welcome: {
      padding: "0px 12px 4px 12px",
      textAlign: "center",
    },
    links: {
      padding: "8px 0px",
    },
    listItem: {
      height: "40px",
      padding: "0px 16px",
      color: theme.palette.tertiary.contrastText,
      "&:hover": {
        backgroundColor: darken(theme.palette.tertiary.main, 0.1),
      },
    },
    listItemNested: {
      padding: "0px 30px",
      eight: "32px",
      backgroundColor: lighten(theme.palette.tertiary.main, 0.3),
      "&:hover": {
        backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
      },
    },
    itemText: {
      fontSize: "14px",
    },
    itemTextNested: {
      fontSize: "14px",
      color: theme.palette.tertiary.contrastText,
    },
    itemActive: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.15),
    },
    subItemActive: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    },
    icon: {
      marginRight: "20px",
      width: "20px",
      height: "20px",
    },
    moreIcon: {
      width: "18px",
      height: "18px",
    },
    divider: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    },
    drawer: (props) => ({
      [theme.breakpoints.up("sm")]: {
        width: props.drawerWidth,
        flexShrink: 0,
      },
      color: theme.palette.tertiary.contrastText,
    }),
    drawerPaper: (props) => ({
      width: props.drawerWidth,
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    }),
  })
);

export default SideMenu;
